import { ThisReceiver } from '@angular/compiler';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tracking } from 'src/app/models/shipping-models';
import { TRACKING_STATUS } from '../enums/shipping.enums';

@Component({
  selector: 'app-tracking-history',
  templateUrl: './tracking-history.component.html',
  styleUrls: ['./tracking-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingHistoryComponent implements OnInit {
  @Input() trackingCode: string | null = null
  @Input() trackingHistory: Tracking[] | null = null;
  @Input() canDelateLast: boolean = false;
  @Output() deleteFromTrackingHistoryEvent = new EventEmitter<[number | undefined, string | null]>();
  private ts = TRACKING_STATUS;
  shippingStatuses: any = [
    { status: this.ts[TRACKING_STATUS.CREATED], icon: 'fa-solid fa-thumbtack', badgeClassColor: 'badge-primary' },
    { status: this.ts[TRACKING_STATUS.EXTERNAL], icon: 'fa-solid fa-share', badgeClassColor: 'badge-info' },
    { status: this.ts[TRACKING_STATUS.SHIPPING], icon: 'fa-solid fa-truck-arrow-right', badgeClassColor: 'badge-secondary' },
    { status: this.ts[TRACKING_STATUS.APPROVED], icon: 'fa-solid fa-truck-fast', badgeClassColor: 'badge-info' },
    { status: this.ts[TRACKING_STATUS.SHIPPED], icon: 'fa-solid fa-map-location-dot', badgeClassColor: 'badge-success' },
    { status: this.ts[TRACKING_STATUS.SHIPPING_FAIL], icon: 'fa-solid fa-exclamation', badgeClassColor: 'badge-warning' },
    { status: this.ts[TRACKING_STATUS.CANCELED], icon: 'fa-solid fa-ban', badgeClassColor: 'badge-danger' },
    { status: this.ts[TRACKING_STATUS.STOCK_MISSING_SENDER], icon: 'fa-solid fa-hourglass-start', badgeClassColor: 'badge-info'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_WRONG_ADDRESS], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_INCORRECT_SIGN], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_INCOMPLETE_ADDRESS], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_TRANSFERRED_ADDRESSEE], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_DECEASED_ADDRESSEE], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_REFUSAL_DELIVERY], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_CEASED_ACTIVITY], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_UNKNOWN_CONSIGNEE], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_ADDRESSEE_UNTRACEABLE], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
    { status: this.ts[TRACKING_STATUS.RETURNED_SENDER_COMPLETED_DELIVERY], icon: 'fa-solid fa-rotate-left', badgeClassColor: 'badge-danger'},
  ];




  constructor() { }

  ngOnInit(): void {
    if (this.trackingHistory && this.trackingHistory.length)
      this.trackingHistory
        .sort((s: Tracking, sp: Tracking) => {
          return new Date(s.date!).getTime() - new Date(sp.date!).getTime();
        });

  }
  getConfigByTrackingStatus(trackingStatus: string): any {
    return this.shippingStatuses.find((s: any) => s.status == trackingStatus);
  }

}
