import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutComponentsModule } from "./layouts/layout-components/layout-components.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';

import { RouterModule } from "@angular/router";

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { StdUserLayoutComponent } from './layouts/std-layout/std-layout.component';
import { CoreModule } from './core/core.module';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { TrackingPageComponent } from './pages/pages-public/tracking-page/tracking-page.component';
import { SharedModule } from './shared/shared.module';
import { ConfirmReadMailComponent } from './pages/pages-public/confirm-read-mail/confirm-read-mail.component';

registerLocaleData(localeIt);
// import { TagInputModule } from "ngx-chips";
// import { FormsModule } from "@angular/forms";
// import { HttpClientModule } from "@angular/common/http";
// import { BsDropdownModule } from "ngx-bootstrap/dropdown";
// import { ToastrModule } from "ngx-toastr";
// import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    StdUserLayoutComponent,
    TrackingPageComponent,
    ConfirmReadMailComponent
  ],
  imports: [
    AppRoutingModule,
    LayoutComponentsModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    // FormsModule,
    // TagInputModule,
    // BsDropdownModule.forRoot(),
    // ToastrModule.forRoot(),
    // CollapseModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
