import { MavApiService } from 'src/app/core/services/mav-api.service';
import { Directive, HostListener, Input, Renderer2 } from '@angular/core';
import { ShippingService } from 'src/app/core/services/shipping.service';
import { take, Observable } from 'rxjs';
import { GetGenerateShippingDocumentPayload, GetShippingDocumentPayload } from 'src/app/models/shipping-models';
import { ReceiverApiService } from 'src/app/core/services/receiver-api.service';
import { SenderApiService } from 'src/app/core/services/sender-api.service';
import { DownloadObj } from 'src/app/models/document-models';
import { ModalService } from 'src/app/core/services/modal.service';
import { ConfiguratorStore } from 'src/app/core/stores/configurator.store';

@Directive({
  selector: '[fileDownload]'
})
export class FileDownloadDirective {
  @Input() fileDownload:
    'shippingToProcess' | 'shippingDoc' |
    'attachDoc' | 'templateReceivers' |
    'templateSenders' | 'envelopeStickerDoc' |
    'mavsZip' | 'templateMavs' | 'shippingStock' | null = null;

  @Input() orientationFile: 'landscape' | 'portrait' | null = null;
  @Input() paper: 'A4' | 'A6' | null = null;
  @Input() targetId: number | null = null;
  @Input() barcode: string | null = null;
  @Input() from: string | null = null;
  @Input() to: string | null = null;
  @Input() envelopeStickerDocDownloadPrint: [any | null, 'print' | 'download' | null] = [null, null];
  constructor(
    private shippingService: ShippingService,
    private receiverApiService: ReceiverApiService,
    private senderApiService: SenderApiService,
    private mavApiService: MavApiService,
    private renderer: Renderer2,
    private modalService: ModalService,
    private configuratorStore: ConfiguratorStore
  ) { }
  @HostListener('click') onClick() {
    console.log('clickDirective', this.fileDownload);
    switch (this.fileDownload) {
      case 'shippingToProcess':
        this.downloadShippingToProcess(this.barcode);
        break;
      case 'shippingStock':
        console.log(this.from, this.to)
        this.downloadShippingStock(this.from, this.to);
        break;
      case 'shippingDoc':
        if (this.targetId)
          if (this.orientationFile && this.paper)
            this.downloadShippingDoc();
          else
            this.downloadShippingDetailDoc('shippingDoc')
        break;
      case 'attachDoc':
        if (this.targetId)
          this.downloadShippingDetailDoc('attachDoc')
        break;
      case 'templateReceivers':
        this.downloadTemplate('receivers')
        break;
      case 'templateSenders':
        this.downloadTemplate('senders')
        break;
      case 'envelopeStickerDoc':
        if (this.envelopeStickerDocDownloadPrint[0] && this.envelopeStickerDocDownloadPrint[1])
          this.downloadPrintEnvelopeStickerDoc(this.envelopeStickerDocDownloadPrint[1])
        break;
      case 'mavsZip':
        this.downloadMavHistory();
        break;
      case 'templateMavs':
        this.downloadTemplateMav();
        break;
      default:
        break;
    }
  };
  private downloadShippingToProcess(barcode: string | null) {
    this.shippingService
      .getPrintShippingsToProcess(barcode)
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) return;
        else return this.handleDocDownloadResponse(res);
      });
  }
  private downloadShippingStock(from: string | null, to: string | null) {
    this.shippingService
      .getPrintShippingsStock(from, to)
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) return;
        else return this.handleDocDownloadResponse(res);
      });
  }
  private downloadShippingDoc() {
    const gsdp: GetGenerateShippingDocumentPayload = {
      shipping_detail_id: this.targetId!,
      orientation: this.orientationFile!,
      paper: this.paper!
    }
    this.shippingService
      .getGenerateShippingDocument({ ...gsdp })
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) return;
        else return this.handleDocDownloadResponse(res);
      });
  }
  private downloadShippingDetailDoc(fileType: 'shippingDoc' | 'attachDoc') {
    const gsdp: GetShippingDocumentPayload = {
      shipping_detail_id: this.targetId!,
      file_type: fileType
    }
    this.shippingService
      .getShippingDetailDocument({ ...gsdp })
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) return;
        else return this.handleDocDownloadResponse(res);
      });
  }
  private downloadTemplate(type: 'receivers' | 'senders') {
    const downloadTemplate: Observable<unknown> =
      type == 'receivers' ?
        this.receiverApiService.downloadTemplateExcel() :
        this.senderApiService.downloadTemplateExcel();

    downloadTemplate
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) return;
        else return this.handleDocDownloadResponse(res);
      });
  }
  private downloadPrintEnvelopeStickerDoc(operation: 'print' | 'download') {
    if (this.envelopeStickerDocDownloadPrint[0]) {
      const downloadObj: DownloadObj | null = this.setDownloadObj(operation);
      this.shippingService.getPrintEnvelopeSticker(downloadObj)
        .pipe(take(1))
        .subscribe(async res => {
          const a = document.createElement('a');
          if (!res) return;
          else if (operation == 'download')
            return this.handleDocDownloadResponse(res);
          else if (operation == 'print')
            return this.handleDocPrintResponse(res);
        });
    }
  }
  private downloadMavHistory() {
    this.mavApiService
      .downloadImportHistory(this.targetId!)
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) return;
        else return this.handleDocDownloadResponse(res);
      });
  }
  private downloadTemplateMav() {
    this.mavApiService.downloadTemplateExcel()
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) return;
        else return this.handleDocDownloadResponse(res);
      });
  }
  async handleDocDownloadResponse(res: any) {
    const contentDisposition = res.headers.get('content-disposition');
    const filename = contentDisposition.split('=')[1];
    const a: HTMLAnchorElement = this.renderer.createElement('a');
    const objectUrl = URL.createObjectURL(res.body!);
    if (!!window.electronAPI) {
      await window.electronAPI.getDocsToPrint({
        url: objectUrl,
        operation: 'download'
      });
    }
    a.setAttribute('href', objectUrl);
    a.setAttribute('download', filename);
    a.click();
    URL.revokeObjectURL(objectUrl);
  }
  async handleDocPrintResponse(res: any) {
    const a: HTMLAnchorElement = this.renderer.createElement('a');
    const objectUrl = URL.createObjectURL(res.body!);
    if (!!window.electronAPI) {
      // view print process modal
      this.modalService.messageModal(
        'Processo di stampa avviato. ',
        '',
        'success',
        false,
        'ok'
      );
      const docsToPrint = await window.electronAPI.getDocsToPrint({
        url: objectUrl,
        docPrinters: this.configuratorStore.docPrintersValue(),
        operation: 'print'
      });
      console.log("docs", docsToPrint);
    }
  }

  /** SET DOWNLOAD OBJ PRINT ZIP */
  private setDownloadObj(operation: 'print' | 'download'): any | null {
    let atLeastOne: boolean = false;
    const ids = Object.keys(this.envelopeStickerDocDownloadPrint[0]);
    let downloadObj: DownloadObj = {
      DL: [],
      C5: [],
      Grande: [],
      Sticker: [],
      Docs: [],
      ShippingDocA6: [],
      ShippingDocA4: [],
      ShippingDocExternal: [],
      ShippingReceipt: [],
      ShippingReceiptAR: [],
      ShippingReceiptARExternal: [],
      operation
    };
    if (ids)
      ids.forEach(id => {
        Object.keys(this.envelopeStickerDocDownloadPrint[0][id]).map(
          (res: string) => {
            if (this.envelopeStickerDocDownloadPrint[0][id][res]) {
              (downloadObj as any)[res].push(Number(id));
              atLeastOne = true;
            }
          }
        );
      })

    if (!atLeastOne)
      return null;

    return { ...downloadObj };

  }
}
