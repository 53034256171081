import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginStore } from '../stores/login.store';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivateChild {
  constructor(
    public router: Router,
    private loginStore: LoginStore
  ) { }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const loginRedirect = environment.loginOdoo;
    if (!environment.production)
      return of(true);
    // const AdminRole = ['admin', 'back-office', 'postman']
    // const StdRole = ['front-office']
    return this.loginStore.userInfo$.pipe(
      map(res => {
        if (res) {
          if (res?.user_role) {
            if (!state.url.includes("admin")) {
              this.router.navigateByUrl('/admin/dashboard');
              return false;
            }
            return true;
          }
          if (!res?.user_role) {
            if (state.url.includes("admin")) {
              this.router.navigateByUrl('/dashboard');
              return false;
            }
            return true;
          }
        }
        // if (state.url.includes("admin") && res?.user_role == "admin")
        //   return true;
        // if (!state.url.includes("admin") && res?.user_role == "admin") {
        //   this.router.navigateByUrl('/admin/dashboard');
        //   return false;
        // }
        // if (!state.url.includes("admin") && res?.user_role == "standard")
        //   return true;
        // if (state.url.includes("admin") && res?.user_role == "standard") {
        //   this.router.navigateByUrl('/dashboard');
        //   return false;
        // }
        window.location.href = loginRedirect;
        return false;

      })
    )
  }
}
