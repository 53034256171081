import { SelectorType } from './../models/shipping-models';
import { DocumentType, ProductType, Accessory } from 'src/app/models/shipping-models';

export default {
  documentTypesList: [
    {
      key: 'upload_documents',
      label:
        '📩 Caricare dei documenti in digitale e ci occuperemo noi della stampa e imbustamento',
    },
    {
      key: 'pickup_documents',
      label:
        "📝 Richiedere un ritiro della corrispondenza all'indirizzo da te indicato",
    },
  ],
  sideDataList: [
    {
      key: 'two_sides',
      label: '📑 Stampa Fronte e Retro',
    },
    {
      key: 'one_side',
      label: '📄 Stampa solo Fronte',
    },
  ],

  colorDataList: [
    {
      key: 'color',
      label: '🎨 Stampa a Colori',
    },
    {
      key: 'black_white',
      label: '⚪⚫ Stampa in Bianco e Nero',
    },
  ],
  accessoriesList: {
    P4: [
      {
        key: 'P1',
        label: '🗺️ Tracking (Rintracciabilità)',
      },
      {
        key: 'PRINT',
        label: '🖨️ Stampa',
        as_default: true
      },
      {
        key: 'COLOR_PRINT',
        label: '🎨 Stampa a Colori',
        as_default: true
      },
      {
        key: 'BLACK_WHITE_PRINT',
        label: '⚪⚫ Stampa in Bianco e Nero',
        as_default: true
      },
      {
        key: 'ENVELOP',
        label: '📩 Imbustamento',
        as_default: true
      }
    ],
    RACC: [
      {
        key: 'RACC_AR',
        label: '🖋️ Prova di consegna',
      },
      {
        key: 'RACC_INSURANCE',
        label: '⛑️ Assicurazione',
      },
      {
        key: 'RACC_SOLID',
        label: '🦾 Busta rigida',
      },
      {
        key: 'PRINT',
        label: '🖨️ Stampa',
        as_default: true
      },
      {
        key: 'COLOR_PRINT',
        label: '🎨 Stampa a Colori',
        as_default: true
      },
      {
        key: 'BLACK_WHITE_PRINT',
        label: '⚪⚫ Stampa in Bianco e Nero',
        as_default: true
      },
      {
        key: 'ENVELOP',
        label: '📩 Imbustamento',
        as_default: true
      }
    ],
  },

  senderTypesList: [
    {
      key: 'useUser',
      label: 'Si',
    },
    {
      key: 'useNew',
      label: 'No',
    },
  ],

  pickupLocationTypesList: [
    {
      key: 'useUser',
      label: 'Si',
    },
    {
      key: 'useNew',
      label: 'No',
    },
  ],

  productType1List: [
    {
      key: 'RACC',
      label: '📨 Raccomandata',
    },
    {
      key: 'P4',
      label: '📬 Posta Ordinaria',
    },
  ],

  productType2List: [
    {
      key: 'STANDARD',
      label: '✉️ Standard',
    },
    {
      key: 'FAST',
      label: '🏃‍♂️ Veloce',
    },
  ],
} as {
  documentTypesList: DocumentType[];
  sideDataList: DocumentType[];
  colorDataList: DocumentType[];
  accessoriesList: { [key: string]: Accessory[] };
  senderTypesList: SelectorType[];
  pickupLocationTypesList: SelectorType[];
  productType1List: ProductType[];
  productType2List: ProductType[];
};
