
export enum ACTION_SHIPMENT {
  'edit',
  'showDetail'
}

export enum TRACKING_STATUS {
  'CREATED',
  'EXTERNAL',
  'SHIPPING',
  'APPROVED',
  'SHIPPED',
  'SHIPPING_FAIL',
  'CANCELED',
  'STOCK_MISSING_SENDER',
  'RETURNED_SENDER_WRONG_ADDRESS',
  'RETURNED_SENDER_INCORRECT_SIGN',
  'RETURNED_SENDER_INCOMPLETE_ADDRESS',
  'RETURNED_SENDER_TRANSFERRED_ADDRESSEE',
  'RETURNED_SENDER_DECEASED_ADDRESSEE',
  'RETURNED_SENDER_REFUSAL_DELIVERY',
  'RETURNED_SENDER_CEASED_ACTIVITY',
  'RETURNED_SENDER_UNKNOWN_CONSIGNEE',
  'RETURNED_SENDER_ADDRESSEE_UNTRACEABLE',
  'RETURNED_SENDER_COMPLETED_DELIVERY',
}
